import * as React from "react";
import FeatureTR from "../components/FeatureTR";
import HeroTR from "../components/HeroTR";
import Layout from "../components/layout/Layout";
import PricingTR from "../components/PricingTR";
import Seo from "../components/seo";
import { graphql } from "gatsby";
import BlogItemTR from "../components/BlogItemTR";

const IndexPage = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes;

  return (
    <Layout>
      <Seo title="Ana Sayfa" />
      <HeroTR />
      <FeatureTR />
      <section className="text-gray-600 body-font mt-20">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-wrap -m-4">
            <div className="flex flex-col text-center w-full">
              <h2 className="text-xs uppercase text-brand tracking-widest font-medium title-font mb-1">
                Blog
              </h2>
              <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900 dark:text-gray-300">
                Referanslarımız
              </h1>
            </div>
            {posts.map((post) => (
              <BlogItemTR post={post} key={post.fields.slug} />
            ))}
          </div>
        </div>
      </section>
      <PricingTR />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { featured: { eq: "1" }, lang: { eq: "tr" } } }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          description
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
