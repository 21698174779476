import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import serverIcon from "../assets/icons/bx_bxs-server.svg";
import gridIcon from "../assets/icons/gridicons_location.svg";
import heroIcon from "../assets/icons/heroicons_sm-user.svg";
import ButtonPrimary from "./misc/ButtonPrimary";

const HeroTR = ({
  listUser = [
    {
      name: "Proje",
      number: "10",
      icon: heroIcon,
    },
    {
      name: "Ülke",
      number: "3",
      icon: gridIcon,
    },
    {
      name: "Ziyaretçi",
      number: "100.000",
      icon: serverIcon,
    },
  ],
}) => {
  return (
    <div className="max-w-screen-xl mt-20 px-8 xl:px-16 mx-auto">
      <div className="grid grid-flex-row sm:grid-flex-col grid-rows-2 md:grid-rows-1 sm:grid-cols-2 gap-8 py-6 sm:py-16">
        <div className="flex flex-col justify-center items-start row-start-2 sm:row-start-1">
          <h1 className="text-3xl lg:text-4xl xl:text-5xl font-medium text-gray-600 dark:text-gray-300 leading-normal">
            Web ve Mobil çözümleriniz için <strong>Istiklal Network</strong>.
          </h1>
          <p className="text-gray-500 dark:text-gray-200 mt-4 mb-6">
            Web tasarımı, web yazılımı ve uygulama geliştirmesi için bizim ile
            iletişime geçmeden önce referanslarımıza bakabilirsiniz.
          </p>
          <Link to="/tr/contact">
            <ButtonPrimary>Bize Ulaşın</ButtonPrimary>
          </Link>
        </div>
        <div className="flex w-full">
          <div className="h-full w-full">
            <StaticImage
              src="../assets/images/illustration1.png"
              alt="VPN Illustrasi"
              quality={100}
              height={383}
              width={612}
            />
          </div>
        </div>
      </div>
      <div className="relative w-full flex">
        <div className="rounded-lg w-full grid grid-flow-row sm:grid-flow-row grid-cols-1 sm:grid-cols-3 py-9 divide-y-2 sm:divide-y-0 sm:divide-x-2 divide-gray-100 bg-white dark:bg-gray-900 z-10">
          {listUser.map((listUsers, index) => (
            <div
              className="flex items-center justify-start sm:justify-center py-4 sm:py-6 w-8/12 px-4 sm:w-auto mx-auto sm:mx-0"
              key={index}
            >
              <div className="flex mx-auto w-40 sm:w-auto">
                <div className="flex items-center justify-center bg-brand-light w-12 h-12 mr-6 rounded-full">
                  <img
                    src={listUsers.icon}
                    className="h-6 w-6"
                    alt="Stats Icon"
                  />
                </div>
                <div className="flex flex-col">
                  <p className="text-xl text-gray-600 dark:text-gray-300 font-bold">
                    {listUsers.number}+
                  </p>
                  <p className="text-lg text-gray-500 dark:text-gray-200">
                    {listUsers.name}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          className="absolute bg-gray-600 opacity-5 w-11/12 rounded-lg h-64 sm:h-48 top-0 mt-8 mx-auto left-8 right-0"
          style={{ filter: "blur(114px)" }}
        ></div>
      </div>
    </div>
  );
};

export default HeroTR;
