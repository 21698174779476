import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const FeatureTR = () => {
  return (
    <div className="max-w-screen-xl mt-8 mb-6 sm:mt-14 sm:mb-14 px-6 sm:px-8 lg:px-16 mx-auto">
      <div className="grid grid-flow-rol sm:grid-flow-col grid-cols-1 sm:grid-cols-2 gap-8 py-8 my-12">
        <div className="flex w-full justify-end">
          <div className="h-full w-full p-4">
            <StaticImage
              src="../assets/images/illustration2.png"
              alt="VPN Illustrasi"
              quality={100}
              height={414}
              width={508}
            />
          </div>
        </div>
        <div className="flex flex-col items-end justify-center ml-auto w-full lg:w-9/12">
          <h3 className="text-3xl lg:text-4xl font-medium leading-relaxed text-gray-600 dark:text-gray-300">
            Size İhtiyacınız Olan Tüm Özellikleri Sunuyoruz
          </h3>
          <p className="my-2 text-gray-500 dark:text-gray-200">
            Her cihazla uyumlu projelerimizi ölçeklendirebilinir şekilde
            tasarlıyoruz. Eğer bir fikriniz varsa bizim ile paylaşabilir,
            yapabileceklerimiz hakkında konuşabiliriz. Projenizi tüm
            platformlarla uyumlu bir şekilde geliştirebiliriz.
          </p>
          <ul className="text-gray-500 dark:text-gray-200 self-start list-inside ml-8">
            <li className="relative circle-check custom-list">
              Her Cihazla Uyumlu Tasarım
            </li>
            <li className="relative circle-check custom-list">
              Arama Motoru Dostu Sonuçlar
            </li>
            <li className="relative circle-check custom-list">
              Uygulama Geliştirme
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FeatureTR;
